import React from 'react'
import classNames from 'classnames'
import Link from '../../components/Link'
import styles from './Breadcrumbs.module.css'

export default ({ className, items = [] }) => {
  return (
    <ul className={classNames(styles['Breadcrumbs'], className)}>
      {items.map((link, index) =>
        <li key={index} className={styles['Breadcrumbs__Item']}>
          <Link to={link.path} className={styles['Breadcrumbs__Link']}>{link.name}</Link>
        </li>
      )}
    </ul>
  )
}
