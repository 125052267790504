import React, { Component } from 'react'
import styles from './MobileHeader.module.css'
import Container from '../../components/Container'
import Logo from '../../components/Logo'
import Icon from '../../components/Icon'
import MobileMenu from '../../components/MobileMenu'

export default class MobileHeader extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isShow: false
    }
  }

  openMenu = event => {
    this.setState({ isShow: true })
  }

  onClose = event => {
    this.setState({ isShow: false })
  }

  render () {
    return (
      <header className={styles['MobileHeader']}>
        <Container className={styles['MobileHeader__Inner']}>
          <span onClick={this.openMenu}>
            <Icon name="Menu" className={styles['MobileHeader__Menu']} />
          </span>
          <Logo />
          <a href="tel:380509937009">
            <Icon name="Phone" className={styles['MobileHeader__Phone']} />
          </a>
        </Container>
        {this.state.isShow && <MobileMenu onClose={this.onClose} />}
      </header>
    )
  }
}
