import React from 'react'
import { Link } from 'gatsby'
import Container from '../Container'
import styles from './MainMenu.module.css'

export default () => {
  const Links = [
    {
      title: 'Главная',
      url: '/'
    },
    {
      title: 'Неисправности',
      url: '/neispravnosti'
    },
    {
      title: 'Услуги',
      url: '/uslugi'
    },
    {
      title: 'Статьи',
      url: '/blog'
    },
    {
      title: 'Контакты',
      url: '/kontakti'
    }
  ]
  return (
    <nav className={styles['MainMenu']}>
      <Container>
        <ul className={styles['MainMenu__List']}>
          {Links.map(link => (
            <li key={link.url}>
              <Link to={link.url} className={styles['MainMenu__Link']}>{link.title}</Link>
            </li>
          ))}
        </ul>
      </Container>
    </nav>
  )
}
