import React from 'react'
import style from './Link.module.css'
import classNames from 'classnames'
import { Link } from 'gatsby'

export default ({ pseudo, simple, className, to, children, onClick=()=>{} }) => {
  if (simple) {
    return <a className={classNames(style['Link'], className)} href={to}>{children}</a>
  }
  if (pseudo) {
    return <span className={classNames(style['Link'], className)} onClick={onClick}>{children}</span>
  }
  return <Link className={classNames(style['Link'], className)} to={to}>{children}</Link>
}
