import React from 'react'
import styles from './LayoutWithSideMenu.module.css'
import Breadcumbs from '../../components/Breadcrumbs'
import Menu from '../../components/Menu'

export default ({ heading, menu = [], breadcrumbs = [], children }) => {
  return (
    <div className={styles['LayoutWithSideMenu']}>
      <div className={styles['LayoutWithSideMenu__Main']}>
        <div className={styles['LayoutWithSideMenu__Headline']}>
          <Breadcumbs items={breadcrumbs} />
          <h1 className={styles['LayoutWithSideMenu__Heading']}>{heading}</h1>
        </div>
        {children}
      </div>
      <aside className={styles['LayoutWithSideMenu__Sidebar']}>
        <Menu items={menu} />
        <p className={styles['LayoutWithSideMenu__About']}>Чистим, настраиваем, ремонтируем и продаем компьютеры, ноутбуки, планшеты, мониторы, видеокарты и другие комплектующие в Павлограде с 2005 года.</p>
      </aside>
    </div>
  )
}
