import React from 'react'
import { Link } from 'gatsby'
import LogoImage from './Logo.png'
import styles from './Logo.module.css'

export default () => {
  return (
    <Link to='/' className={styles['Logo']}>
      <img className={styles['Logo__Image']} src={LogoImage} alt="Логотип АлексКомп" />
    </Link>
  )
}
