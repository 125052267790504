import React from 'react'
import Container from '../Container'
import styles from './Header.module.css'
import Logo from '../../components/Logo'
import SocialSnippet from '../../components/SocialSnippet'
import PhonesSnippet from '../../components/PhonesSnippet'

export default () => {
  return (
    <header className={styles['Header']}>
      <Container className={styles['Header__Inner']}>
        <div className={styles['Header__Logo']}>
          <Logo />
          <span className={styles.Header__LogoDescription}>Ремонтируем и продаем<br />цифровую технику</span>
        </div>
        <SocialSnippet />
        <PhonesSnippet />
      </Container>
    </header>
  )
}
