import React, { Component } from 'react'
import Link from '../../components/Link'
import Icon from '../../components/Icon'
import styles from './PhonesSnippet.module.css'

export default class PhonesSnippet extends Component {
  constructor (props) {
    super(props)

    this.state = { isShow: false }
  }

  render () {
    const Phones = [
      {
        number: '0509937009',
        work: true,
        for: 'Мастерская'
      },
      {
        number: '0995456071',
        work: false,
        for: 'Мастер на дом'
      }
    ]

    let workingText;
    const day = new Date().getDay();
    const time = new Date().getHours();

    if ((day >= 6) && (time >= 9) && (time <= 17)) {
      workingText = 'Мы открыты до 17:00'
    } else if ((day > 0) && (time >= 9) && (time <= 18)) {
      workingText = 'Мы открыты до 18:00'
    } else {
      workingText = 'Сейчас мы закрыты'
    }

    return (
      <div className={styles['PhonesSnippet']}>
        <Icon name="Phone" className={styles['PhonesSnippet__Icon']} />
        <div className={styles['PhonesSnippet__Contant']}>
          <ul className={styles['PhonesSnippet__List']}>
            {Phones.map(phone =>
              (workingText === 'Сейчас мы закрыты' || phone.work) && <li className={styles['PhonesSnippet__Item']} key={phone.number}>
                <Link className={styles['PhonesSnippet__Link']} simple to={`tel:38${phone.number}`}>{phone.number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4')}</Link>
                {(workingText === 'Сейчас мы закрыты') && <small className={styles['PhonesSnippet__For']}>{phone.for}</small>}
              </li>
            )}
          </ul>
          <Link pseudo onClick={this.handleOpen} className={styles['PhonesSnippet__Time']}>{workingText}</Link>

          { this.state.isShow &&
            <div className={styles['PhonesSnippet__Worktime']}>
              <div className={styles['PhonesSnippet__WorktimeTitle']}>Время работы</div>
              <dl className={styles['PhonesSnippet__WorktimeItems']}>
                <dt className={styles['PhonesSnippet__WorktimeDay']}>По будням</dt>
                <dd className={styles['PhonesSnippet__WorktimeTime']}>c 9 до 18</dd>
                <dt className={styles['PhonesSnippet__WorktimeDay']}>По субботам</dt>
                <dd className={styles['PhonesSnippet__WorktimeTime']}>c 9 до 17</dd>
                <dt className={styles['PhonesSnippet__WorktimeDay']}>В воскресенье</dt>
                <dd className={styles['PhonesSnippet__WorktimeTime']}>закрыты</dd>
              </dl>
            </div>
          }
        </div>
      </div>
    )
  }

  handleOpen = event => {
    this.setState({ isShow: !this.state.isShow })
  }
}
