import React from 'react'
import Helmet from 'react-helmet'
import Media from 'react-media'
import styled from 'styled-components'

import MobileHeader from '../components/MobileHeader'
import MobileMenu from '../components/MobileMenu'
import Header from '../components/Header'
import MainMenu from '../components/MainMenu'
import Footer from '../components/Footer'

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const Main = styled.div`
  flex: 1;
`

const Layout = ({ children, ...props }) => (
  <Root {...props}>
    <Media query="(max-width: 768px)">
      {matches =>
        matches ? (
          <div>
            <MobileHeader />
          </div>
        ) : (
          <div>
            <Header />
            <MainMenu />
          </div>
        )
      }
    </Media>
    <Main>
      {children}
    </Main>
    <Footer />
  </Root>
)

export default Layout;
