import React from 'react'
import Link from '../Link'
import Container from '../Container'
import styles from './Footer.module.css'

export default () => {
  const Links = [
    {
      title: 'Главная',
      url: '/'
    },
    {
      title: 'Неисправности',
      url: '/neispravnosti'
    },
    {
      title: 'Услуги',
      url: '/uslugi'
    },
    {
      title: 'Статьи',
      url: '/blog'
    },
    {
      title: 'Контакты',
      url: '/kontakti'
    }
  ];

  return (
    <footer className={styles['Footer']}>
      <Container>
        <ul className={styles['Footer__Menu']}>
          {Links.map(link => (
            <li key={link.url}>
              <Link to={link.url} className={styles['Footer__Link']}>{link.title}</Link>
            </li>
          ))}
        </ul>
        <div className={styles['Footer__Copyright']}>© «АлексКомп», 2005-{new Date().getFullYear()}</div>
      </Container>
    </footer>
  )
}
