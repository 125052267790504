import React from 'react'
import Icon from '../../components/Icon'
import styles from './SocialSnippet.module.css'

export default () => {
  return (
    <div className={styles['SocialSnippet']}>
      <h4 className={styles['SocialSnippet__Title']}>Присоединяйся</h4>
      <div className={styles['SocialSnippet__Links']}>
        <a href="https://vk.com/alexcomp_pavlograd" target="_blank">
          <Icon name="Vk" />
        </a>
        <a href="https://www.facebook.com/alexcomp.pavlograd" target="_blank">
          <Icon name="Fb" />
        </a>
        <a href="https://plus.google.com/101811556206598726864" target="_blank">
          <Icon name="Gp" />
        </a>
      </div>
    </div>
  )
}
