import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Logo from '../../components/Logo'
import styles from './MobileMenu.module.css'

export default ({ onClose }) => {
  const menu = [
    {
      name: 'Главная',
      path: '/'
    },
    {
      name: 'Неисправности',
      path: '/neispravnosti'
    },
    {
      name: 'Услуги',
      path: '/uslugi'
    },
    {
      name: 'Статьи',
      path: '/blog'
    },
    {
      name: 'Контакты',
      path: '/kontakti'
    }
  ]
  return (
    <div className={styles['MobileMenu']} onClick={onClose}>
      <div className={styles['MobileMenu__Container']}>
        <div className={styles['MobileMenu__Logo']}>
          <Logo />
        </div>
        <Menu items={menu} />
      </div>
    </div>
  )
}
