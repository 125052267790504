import React from 'react'
import styles from './Menu.module.css'
import Link from '../../components/Link'

export default ({ items }) => {
  return (
    <ul className={styles['Menu']}>
      {items.map((item, index) =>
        <li className={styles['Menu__Item']} key={index}>
          <Link className={styles['Menu__Link']} to={item.path}>{item.name}</Link>
        </li>
      )}
    </ul>
  )
}
