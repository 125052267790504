import React from 'react'
import classNames from 'classnames'
import styles from './Icon.module.css'

console.log(styles)

export default ({ name, className }) => {
  return (
    <span className={classNames(styles['Icon'], styles[`Icon${name}`], className)} />
  )
}
